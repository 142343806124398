.brands{
    background: $graylight;
    .brands-inner{
        max-width: 1440px;
        margin: 0 auto;
        padding: 20px 20px 50px;
        @include media-query (medium-up){
            padding: 20px 40px 80px;
        }
    }
    .brands-title__container{
        text-align: center;
        margin-bottom: 10px;
        @include media-query (medium-up){
            margin-bottom: 15px;
        }
    }
    .slick-slide{
        padding: 10px;
        img{
            background: $white;
        }
    }
    // Slick 
    .slick-prev,
    .slick-next{
        top: 50%;
        position: absolute;   
        bottom: 0;
        z-index: 1;
        width: 25px;
        height: 25px;
        background: $black;
        left: 0;
        transform: translateY(-50%);
    }
    .slick-prev{
        left: -10px;
        &:before{
            line-height: 1.3;
            opacity: 1;
        }
    }
    .slick-disabled{
        opacity: 0.2;
        cursor: default;
    }
    .slick-next{
        right: -10px;
        left: initial;
        &:before{
            line-height: 1.3;
            opacity: 1;
        }
    }
    // Chrome extension shoutout
    .brands__chrome-extension-shout{
        text-align: center;
        max-width: 700px;
        width: 95%;
        margin: 0 auto;
        padding: 10px;
        font-size: 14px;
        a{
            color: $primaryColor;
            &:hover,
            &:focus{
                text-decoration: none;
                opacity: 0.7;
            }
        }
    }
}