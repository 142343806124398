.about{
    max-width: 1440px;
    margin: 0 auto;
    &.about--padding{
        padding-top: 52px;
    }
}

.about-inner{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 60px 20px 50px;
    @include media-query (medium-up){
        padding: 60px 40px 80px;
    }
}

.about-title__container{
    display: flex;
}

.about-title{
    color: #04C2C9;
    border-bottom: 3px solid #04C2C9;
    margin-bottom: 25px;
    @extend h2;
}

.about-left,
.about-right{
    width: 100%;
    @include media-query (large-up){
        width: 50%;
    }
    p{
        line-height: 1.4;
        font-size: 15px;
    }
}
.about-left{
    @include media-query (large-up){
        padding-right: 30px;
    }
    p{
        margin-bottom: 20px;
    }
}
.about-right{
    @include media-query (large-up){
        padding-left: 30px;
    }
}
.about-right__image-container{
    display: none;
    @include media-query (large-up){
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.about-left__details-container{
    position: relative;
    @include media-query (large-up){
        margin: 50px 0 0;
    }
}

.about-left__details-image{
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 30px);
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("https://images.pexels.com/photos/2085998/pexels-photo-2085998.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
    @include media-query (large-up){
        width: calc(100% - 40px);
    }
}

.about-left__details-text-container{
    position: relative;
    top: 30px;
    left: 30px;
    background: white;
    width: 100%;
    line-height: 1.3;
    z-index: 1;
    border: 2px solid $gray;
    padding: 20px;
    width: calc(100% - 30px);
    @include media-query (large-up){
        top: 40px;
        left: 40px;
        width: calc(100% - 40px);
    }
}

.about-right__image{
    width: 80%;
}
.about-right__icons-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin: 75px auto 0 auto;
    @include media-query (large-up){
        width: 92%;
        max-width: 100%;
    }
}
.about-right__icons-each{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0;
}









.about-right__icons-each-image-container{
    height: 67px;
    width: 100px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
}
.about-right__icons-each-image{
    background-color: #04C2C9;
    height: 66px;
    width: 37px;
    text-align: center;
    position: relative;
    display: inline-block;
    &:before,
    &:after{
        position: absolute;
        top: 0;
        right: 0;
        background-color: inherit;
        height: 66px;
        width: 37px;
        content: '';
    }
    &:before{
        transform: rotateZ(60deg);
    }
    &:after{
        transform: rotateZ(-60deg);
    }
    svg{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill :$white;
        z-index: 1;
        width: 32px;
        height: 100%;
    }
}

.about-right__icons-each-title{
    @extend h3;
}
.about-right__icons-each-subtitle{
    font-size: 14px;
    margin: 0 auto;
    @include media-query (medium-up){
        font-size: 16px;
    }
}











.about-content__text{
    margin-left: 20px;
}


.about-content__description-container{
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin: 15px auto 0 auto;
    padding: 0 15px;
    line-height: 2;
    @include media-query (medium-up){
        max-width: 75%;
        margin-top: 35px;
        padding: 0;
    }
}
.about-content__description{
    font-size: 14px;
    @include media-query (medium-up){
        font-size: 16px;
    }
}