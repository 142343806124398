.portfolio{
    background: #f5f5f5;
    padding: 60px 0 20px;
    @include media-query (medium-up){
        padding: 60px 20px 20px;
    }
}
.portfolio-title__container{
    display: flex;
    justify-content: center;
}
.portfolio-title{
    text-align: center;
    margin-bottom: 15px;
    border-bottom: 3px solid $graydark;
    @include media-query (medium-up){
        margin-bottom: 25px;
        margin-top: 25px;
    }
}
.portfolio-description{
    text-align: center;
    margin-bottom: 25px;
    padding: 0 20px;
}
.portfolio-content__container{
    display: flex;
    flex-wrap: wrap;
    padding: 18px;
    border: 0;
    max-width: 1440px;
    margin: 0 auto;
}