.portfolio-content{
    width: 100%;
    cursor: pointer;
    border: 0;
    box-shadow: hsl(0, 0%, 50%) 0 0 6px;
    margin-bottom: 20px;
    border-radius: 4px;
    padding: 0;
    @include media-query (small-up){
        padding: 9px;
        width: 50%;
        box-shadow: none;
        margin-bottom: 0;
    }
    @include media-query (medium-up){
        padding: 18px;
    }
    @include media-query (large-up){
        width: 33.33%;
    }

    &.disabled{
        pointer-events: none;
        .portfolio-content__image-overlay{
            background: $black;
            opacity: 0.9;
        }
    }
}
.portfolio-content__image-container{
    position: relative;
    width: 100%;
    font-size: 0;
    border-radius: 4px;
    @include media-query (small-up){
        border-radius: 0;
    }
    @include media-query (small-up){
        &:hover{
            .portfolio-content__image-overlay{
                opacity: 1;
            }
        }
    }
}
.portfolio-content__image-overlay{
    transition-duration: 0.2s;
    background: rgba(27, 36, 47, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    border-radius: 4px;
    opacity: 1;
    @include media-query (medium-up){
        border-radius: 0;
        opacity: 0;
    }
    &:hover{
        opacity: 1;
    }
}
.portfolio-content__image-title{
    color: $white;
    letter-spacing: 0.04rem;
    max-height: 27px;
    height: auto;
    margin-bottom: 15px;
    max-width: 125px;
}
.portfolio-content__image-disabled-title{
    color: $white;
    letter-spacing: 0.04rem;
    margin-bottom: 15px;
    font-size: 18px;
}
.portfolio-content__image-overlay-btn{
    border: 2px solid $primaryColor;
    color: $primaryColor;
    background: $white;
    padding: 6px 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    transition-duration: 0.2s;
    @include media-query (medium-up){
        &:hover{
            background: $primaryColor;
            color: $white;
        }
    }
    &:focus{
        background: $primaryColor;
        color: $white;
    }
}
.portfolio-content__image{
    width: 100%;
    border-radius: 4px;
    @include media-query (small-up){
        border-radius: 0;
    }
}